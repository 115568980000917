import * as React from 'react'

import { Select } from '@toasttab/buffet-pui-dropdowns'

export function App() {
  React.useEffect(() => {
    console.log('The tadpole test spa has mounted')
  })
  const [selected, setSelected] = React.useState()
  return (
    <div className='container py-4 m-auto md:px-4'>
      <Select
        value={selected}
        options={['One', 'Two', 'Three']}
        onChange={setSelected}
      />
    </div>
  )
}
